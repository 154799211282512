<template>
  <div class="w-100 d-flex flex-column">
    <base-tool-bar title="Préparation d'affaire" subtitle="Informations générales"></base-tool-bar>
    <task-manager class="mt-3 mb-3" :id="$route.params.id" system="prepa_affaire"></task-manager>
    <base-form class="mr-md-3" @click="updateAffaire" :loading="loading" shadow>
      <base-form-group title="Analyses préalables" dense icon="check-square">
        <div class="pt-3 text-justify">
          En tant que pilote d’affaire, le porcessus général vous impose une série d'actions de préparation. Les tâches
          indiquées ci-avant sont nécessaires et obligatoires dans la plupart des cas. Vous pouvez également vous
          affranchir de pièce jointe et placer dans le champ ci-après un résumé de vos actions de préparation.
        </div>
        <base-form-row class="mb-4">
          <base-input-area
            v-model="affaire_prepa"
            text="Synthèse de la préparation d'affaire"
            :errors="feedback.affaire_prepa"
          ></base-input-area>
        </base-form-row>
        <!-- <base-form-row>
         <quiz
            ref="questionnaireAtpSelect"
            name="questionnaireAtp"
            title="Analyse technique préalable"
            text="Répondre au questionnaire checklist de démarrage"
            subtitle
            @end="hideQuiz"
            :questionnaire_id="1"
            :questionnaire_field="id"
          ></quiz>
        </base-form-row>-->
        <uploader
          v-if="id"
          id="document_affaire_prepa"
          url="/upload/document"
          title="Pièces jointes"
          :file_system="'CRM'"
          :file_field="'prepa'"
          :file_key="id"
        ></uploader>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
//import Quiz from "@/components/bases/Quiz.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
//import BaseInput from "@/components/bases/Input.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import Uploader from "@/components/bases/Uploader.vue";
import TaskManager from "@/components/application/TasksManager.vue";

export default {
  components: {
    BaseToolBar,
    BaseForm,
    BaseFormGroup,
    //Quiz,
    BaseFormRow,
    //BaseInput,
    BaseInputArea,
    Uploader,
    TaskManager,
  },
  data() {
    return {
      loading: false,
      lists: {},
      feedback: {},
      test: "",
    };
  },
  computed: {
    ...mapFields("affaires", ["affaire.id", "affaire.affaire_prepa"]),
  },
  methods: {
    ...mapActions({
      update: "affaires/updateAffaire",
    }),
    updateAffaire: function () {
      this.loading = true;
      this.feedback = {};
      this.update()
        .catch((error) => this.errHandler(error))
        .finally(() => (this.loading = false));
    },
    errHandler: function (error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
    hideQuiz: function () {
      this.$refs.questionnaireAtpSelect.hideModal();
    },
  },
};
</script>
